<template>
  <div class="curriculum_containercontainer">
    <div class="banber_swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in banner"
            :key="index">
            <img :src="item.image" alt="" class="banber"  @click="href(item.jump_url)"/>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="breadcrumb_wrap m_1200 p_t_42">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{cid==14?'心理新闻':'心理课程'}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="list_box m_1200">
      <router-link
        :to="'/knowledgeDetail?id=' + item.id"
        tag="div"
        class="list_item"
        v-for="(item, index) in listData"
        :key="index"
      >
        <img :src="item.img" alt="" class="photo" />
        <div class="content">
          <p class="title">{{ item.title }}</p>
          <div class="bar_wrap">
            <div class="date">{{ item.create_time }}</div>
            <div class="look">{{ item.views }}阅读</div>
          </div>
          <div class="btn">查看详情>></div>
        </div>
      </router-link>
    </div>
    <div class="page_box m_1200">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :current-page="pageNo"
        :page-size="pageSize"
        :background="true"
        :small="device"
        :layout="device ? 'prev, pager, next' : 'total, sizes, prev, pager, next, jumper'"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex' 
export default {
  name: 'Knowledge',
  data () {
    return {
      current: 11, //当前tab下标
      pageNo: 1, //页码
      pageSize: 10, //页数
      total: 0, //总数
      listData: [], //列表数据
      banner: []
    }
  },
  computed: {
    ...mapState(['device'])
  },
  mounted () {
	this.cid=this.$route.query.cid
    // 轮播图
    this.$http({
      method: 'POST',
      url: '/Banner/banner',
      data: {}
    }).then(res => {
      this.banner = res.lists
    })
    this.getList()
    this.initSwiper()
  },
  methods: {
	  href(url){
	  	if(url.indexOf("http") != -1){
	  		window.open(url)
	  	}
	  },
    getList () {
      this.$http({
        method: 'POST',
        url: '/News/news',
        data: {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          cate_id: this.cid
        }
      }).then(res => {
        this.listData = res.data
        this.total = res.count
      })
    },

    //初始化轮播图
    initSwiper () {
      this.$nextTick(() => {
        new Swiper('.banber_swiper .swiper-container', {
          loop: true,
          autoplay: true,
          pagination: {
            el: '.swiper-pagination'
          }
        })
      })
    },
    // tabs发生点击
    handleTabs (index) {
      this.current = index
    },
    //页数发生改变
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    //页码发生改变
    handleCurrentChange (val) {
      this.pageIndex = val
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.curriculum_containercontainer {
  .banber_swiper {
    display: block;
    width: 100%;
    height: 578px;
    .swiper-container {
      position: relative;
    }
  }
  .tab_box {
    display: flex;
    justify-content: center;
    padding: 42px 0;
    .tab_item {
      box-sizing: border-box;
      width: 145px;
      height: 55px;
      font-size: 18px;
      text-align: center;
      line-height: 55px;
      border: 1px solid #e51418;
      border-radius: 3px;
      transition: 0.3s all linear;
      cursor: pointer;
      & + .tab_item {
        margin-left: 10px;
      }
      &.active {
        color: #fff;
        background-color: #e51418;
      }
    }
  }
  .list_box {
    .list_item {
      display: flex;
      padding: 16px;
      cursor: pointer;
      transition: 0.3s all linear;
      border-radius: 5px;
      &:hover {
        background-color: #f4f4f4;
        .content {
          .btn {
            opacity: 1;
            color: #fff;
            background: #e51418;
            border-color: #e51418;
          }
        }
      }
      & + .list_item {
        margin-top: 35px;
      }
      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        .title {
          font-size: 20px;
          font-weight: bold;
        }
        .bar_wrap {
          display: flex;
          align-items: center;
          height: 25px;
          padding: 27px 0 22px;
          line-height: 25px;
          color: #666666;
          font-size: 18px;
          .date {
            height: 25px;
            margin-right: 16px;
            padding-left: 32px;
            background: url('../assets/images/knowledge2.png') no-repeat left
              center;
            background-size: 25px 25px;
          }
          .look {
            height: 25px;
            margin-right: 16px;
            padding-left: 32px;
            background: url('../assets/images/knowledge3.png') no-repeat left
              center;
            background-size: 30px 16px;
          }
        }
        .text {
          font-size: 14px;
          line-height: 1.5;
          text-align: justify;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .btn {
          width: 112px;
          height: 31px;
          margin-top: auto;
          // margin-left: auto;
          line-height: 31px;
          text-align: center;
          font-size: 16px;
          transition: 0.3s all linear;
          border-radius: 5px;
          border: 1px solid #333333;
        }
      }
      .photo {
        flex: 0 0 333px;
        height: 180px;
      }
    }
  }
  .page_box {
    padding: 60px 0;
    text-align: right;
  }
}
@media screen and (max-width: 768px) {
  .curriculum_containercontainer {
    .banber_swiper {
      display: block;
      width: 100%;
      height: 180px !important;
      img {
        width: 100%;
        height: 180px;
      }
      .swiper-container {
        position: relative;
      }
    }
    .breadcrumb_wrap {
      margin: 15px;
      padding-top: 0;
    }
    .tab_box {
      padding: 15px 0;
      .tab_item {
        box-sizing: border-box;
        width: 80px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        border: 1px solid #e51418;
        border-radius: 3px;
        transition: 0.3s all linear;
        cursor: pointer;
        & + .tab_item {
          margin-left: 10px;
        }
        &.active {
          color: #fff;
          background-color: #e51418;
        }
      }
    }
    .list_box {
      .list_item {
        display: flex;
        padding: 16px;
        cursor: pointer;
        transition: 0.3s all linear;
        border-radius: 5px;
        &:hover {
          background-color: #f4f4f4;
          .content {
            .btn {
              opacity: 1;
              color: #fff;
              background: #e51418;
              border-color: #e51418;
            }
          }
        }
        & + .list_item {
          margin-top: 35px;
        }
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 12px;
          .title {
            font-size: 16px;
            font-weight: bold;
          }
          .bar_wrap {
            display: flex;
            align-items: center;
            height: 25px;
            padding: 12px 0;
            line-height: 25px;
            color: #666666;
            font-size: 14px;
            .date {
              height: 25px;
              margin-right: 8px;
              padding-left: 18px;
              white-space: nowrap;
              background: url('../assets/images/knowledge2.png') no-repeat left
                center;
              background-size: 15px 15px;
            }
            .look {
              height: 25px;
              margin-right: 0;
              padding-left: 24px;
              background: url('../assets/images/knowledge3.png') no-repeat left
                center;
              background-size: 18px 12px;
            }
          }
          .text {
            font-size: 14px;
            line-height: 1.5;
            text-align: justify;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .btn {
            width: 80px;
            height: 24px;
            margin-top: auto;
            // margin-left: auto;
            line-height: 24px;
            text-align: center;
            font-size: 12px;
            transition: 0.3s all linear;
            border-radius: 5px;
            border: 1px solid #333333;
          }
        }
        .photo {
          flex: 0 0 120px;
          height: 100px;
        }
      }
    }
    .page_box {
      padding: 60px 0;
      text-align: center;
    }
  }
}
</style>
